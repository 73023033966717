import React from 'react';
import classNames from 'classnames';
import { SectionSplitProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import Image from '../elements/Image';

const propTypes = {
  ...SectionSplitProps.types,
};

const defaultProps = {
  ...SectionSplitProps.defaults,
};

const Partners = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  invertMobile,
  invertDesktop,
  alignTop,
  imageFill,
  pushLeft,
  ...props
}) => {
  const outerClasses = classNames(
    'features-split section',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'features-split-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  const tilesClasses = classNames('tiles-wrap', pushLeft && 'push-left');

  const sectionHeader = {
    title: 'Partners',
    paragraph:
      'Explore our ever growing list of market leading enterprises that have partnered with the Slyzed platform. Please reach out if you want to partner with the latest, global innovative social platform.',
  };

  return (
    <section {...props} className={outerClasses}>
      <div className='container-sm'>
        <div className={innerClasses}>
          <div className={tilesClasses}>
            <SectionHeader data={sectionHeader} className='center-content' />
            <div
              className='tiles-item reveal-from-right'
              data-reveal-delay='200'
            >
              <div className='tiles-item-inner'>
                <div className='support-tile-item-content'>
                  <Image
                    src='https://res.cloudinary.com/slyzed/image/upload/v1656624021/slyzed-shops/ict/ICT_College_Flag.jpg'
                    alt='slyzed-ict-logo'

                    className='rounded-corners'
                  />{' '}
                </div>
              </div>
            </div>
            <div
              className='tiles-item reveal-from-right'
              data-reveal-delay='200'
            >
              <div className='tiles-item-inner'>
                <div className='support-tile-item-content'>
                  <Image
                    src='https://res.cloudinary.com/slyzed/image/upload/v1658432544/slyzed-platform/slyzed-equity-bank-logo.png'
                    alt='slyzed-equity-bank-logo'
                    width={300}
                    height={150}
                    className='rounded-corners'
                  />{' '}
                </div>
              </div>
            </div>                          
            <div
              className='tiles-item reveal-from-right'
              data-reveal-delay='200'
            >
              <div className='tiles-item-inner'>
                <div className='support-tile-item-content'>
                  <Image
                    src='https://res.cloudinary.com/slyzed/image/upload/v1658179187/slyzed-platform/slyzed-mpesa-logo.png'
                    alt='slyzed-mpesa-logo'
                    className='rounded-corners'
                  />{' '}
                </div>
              </div>
            </div>
            <div
              className='tiles-item reveal-from-right'
              data-reveal-delay='200'
            >
              <div className='tiles-item-inner'>
                <div className='support-tile-item-content'>
                  <Image
                    src='https://res.cloudinary.com/slyzed/image/upload/v1658433177/slyzed-platform/slyzed-stripe-logo.png'
                    alt='slyzed-stripe-logo'
                    className='rounded-corners'
                  />{' '}
                </div>
              </div>
            </div>            
            <div
              className='tiles-item reveal-from-right'
              data-reveal-delay='200'
            >
              <div className='tiles-item-inner'>
                <div className='support-tile-item-content'>
                  <Image
                    src='https://res.cloudinary.com/slyzed/image/upload/v1659048396/slyzed-platform/slyzed-safaricom-logo.png'
                    alt='slyzed-safaricom-logo'
                    className='rounded-corners'
                  />{' '}
                </div>
              </div>
            </div>
            <div
              className='tiles-item reveal-from-right'
              data-reveal-delay='200'
            >
              <div className='tiles-item-inner'>
                <div className='support-tile-item-content'>
                  <Image
                    src='https://res.cloudinary.com/slyzed/image/upload/v1679951221/slyzed-platform/slyzed-microsoft-for-startups.jpg'
                    alt='slyzed-microsoft-for-startups-logo'
                    className='rounded-corners'
                  />{' '}
                </div>
              </div>
            </div>            
            <div
              className='tiles-item reveal-from-right'
              data-reveal-delay='200'
            >
              <div className='tiles-item-inner'>
                <div className='support-tile-item-content'>
                  <Image
                    src='https://res.cloudinary.com/slyzed/image/upload/v1658432698/slyzed-platform/slyzed-apfome-logo.png'
                    alt='slyzed-apfome-logo'
                    className='rounded-corners'
                  />{' '}
                </div>
              </div>
            </div> 
            <div
              className='tiles-item reveal-from-right'
              data-reveal-delay='200'
            >
              <div className='tiles-item-inner'>
                <div className='support-tile-item-content'>
                  <Image
                    src='https://res.cloudinary.com/slyzed/image/upload/v1679951201/slyzed-platform/slyzed-pezesha-logo.png'
                    alt='slyzed-pezesha-logo'
                    className='rounded-corners'
                  />{' '}
                </div>
              </div>
            </div>                     
          </div>
        </div>
      </div>
    </section>
  );
};

Partners.propTypes = propTypes;
Partners.defaultProps = defaultProps;

export default Partners;
