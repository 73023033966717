import classNames from 'classnames';
import React from 'react';
import Input from '../elements/Input';
import SectionHeader from './partials/SectionHeader';

// a basic form
const SimpleForm = ({
  status,
  message,
  className,
  style,
  onSubmitted,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  split,
  ...props
}) => {
  const outerClasses = classNames(
    'cta section center-content-mobile reveal-from-bottom',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'cta-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider',
    split && 'cta-split'
  );

  const sectionHeader = {
    title: 'Contact Us',
    paragraph: 'Get in touch with the Slyzed team!',
  };

  const submit = () =>
    document.getElementById('email') &&
    document.getElementById('fname') &&
    document.getElementById('lname') &&
    document.getElementById('email').value.indexOf('@') > -1 &&
    onSubmitted({
      EMAIL: document.getElementById('email').value,
      FNAME: document.getElementById('fname').value,
      LNAME: document.getElementById('lname').value,
      MMERGE3: document.getElementById('msg').value,
    });

  return (
    <section {...props} className={outerClasses}>
      <div className='container'>
        <div className={innerClasses}>
          <div className='cta-slogan'>
            <SectionHeader data={sectionHeader} className='center-content' />
          </div>
          <div className={className} style={style}>
            {status === 'sending' && (
              <div style={{ color: 'blue' }}>sending...</div>
            )}
            {status === 'error' && (
              <div
                style={{ color: 'red' }}
                dangerouslySetInnerHTML={{ __html: message }}
              />
            )}
            {status === 'success' && (
              <div
                style={{ color: 'green' }}
                dangerouslySetInnerHTML={{ __html: message }}
              />
            )}
            <div className='cta-action'>
              <Input
                id='fname'
                type='text'
                label='Forename'
                placeholder='Forename'
              ></Input>
              <Input
                id='lname'
                type='text'
                label='Surname'
                placeholder='Surname'
              ></Input>
              <Input
                id='email'
                type='email'
                label='email'
                placeholder='Email'
              ></Input>
              <Input
                id='msg'
                type='textarea'
                label='Message'
                placeholder='Your message'
              ></Input>
              <div className='float-r'>
                <button
                  className='button button-primary rounded-corners button-sm fixed mt-12'
                  color={'primary'}
                  onClick={submit}
                >
                  Send
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SimpleForm;
