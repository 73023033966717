export const whatIsSlyzedAccordionData = [
  {
    title: 'What is Slyzed?',
    content: `
    Slyzed offers an innovative platform to sell and buy products and make money from the value of products or services sold. 
    Selling is no longer about getting a product and bringing it to market, it is convergence of social media technology and involvement of the community in the sales process. 
    The platform empowers businesses and the community to work together and break through the social commerce walls and increase sales of products and services.
    `,
  },
  {
    title: 'Buyer',
    content: `
    By default any new account opened on Slyzed allows you to be able to purchase products from the platform.
    `,
  },
  {
    title: 'Account Upgrade',
    content:`
    Any buyer account can be upgraded to a Seller or Reseller to be able to sell or resell goods and services on the platform.
    `,
  },
  {
    title: `How much does it cost to upgrade?`,
    content: `
    You can upgrade your account from your profile and your account will automatically be upgraded for free
    `,
  },
  {
    title: 'Seller',
    content: `
    A buyer account can be upgraded to a seller account. This allows the seller to create a shop and once the shop has been approved the seller can add goods and services for sale on the platform.
    Each product has to have a selling and slightly higher reseller price, ready for resellers to share and sell them to family and friends
    `,
  },
  {
    title: 'Reseller',
    content: `
    A buyer account can also be upgraded to be a reseller account. This allows the reseller to share goods and services from sellers to resell them on the platform.
    For more details you can watch the video about Slyzed.
    `,
  },
];

export const accountManagementAccordionData = [
  {
    title: 'Opening an account on Slyzed',
    content: `    
    You must be at least 18 Years old to create a Slyzed account.
    Go to https://app.slyzed.com and click on the Sign in or Sign up button.
    A pop up box will appear, scroll to the bottom and select the option don't have an account?
    Sign up now and you'll be guided through our sign up experience.
    You'll be prompted to enter information such as your name and phone number and postcode or email address.
    Enter your name, email address or mobile phone number, password and postcode.
    To finish creating your account, you need to confirm your email address or mobile phone number, we will immediately send you a text message with a code so we can verify your number, for emails we will send you instructions so we can verify your email address.
    After entering your information, click Create.
`,
  },
  {
    title: 'Signing up on Slyzed?',
    content: `
    Slyzed has made it easier for users to log in or sign up to our social commerce platform.
    Users can create accounts and directly sign up/log in to Slyzed using their Google, Facebook, Twitter or Microsoft account.
    `,
  },
  {
    title: 'Sign up with your Google account',
    content: `
    Go to https://app.slyzed.com and click on the Sign in or Sign up button.
    On the sign in page, select Sign in with your social account. Click on Google, then Choose the Google account you'd like to use to sign up/log in to Slyzed.
    `,
  },
  {
    title: 'Sign up with your Microsoft account',
    content: `
    Go to https://app.slyzed.com and click on the Sign in or Sign up button.  
    On the sign in page, select Sign in with your social account. Click on Microsoft, Then choose your Microsoft email account you'd like to use to sign up/log in to Slyzed.
    `,
  },
  {
    title: 'Sign up with your Twitter account',
    content: `
    Go to https://app.slyzed.com and click on the Sign in or Sign up button.
    On the sign in page, select Sign in with your social account. Cick on Twitter to use your Twitter account to sign up/log in to Slyzed. 
  `,
  },
  {
    title: 'Locked accounts',
    content: `
    Contact us if your Account has been locked. We will try and re-activate your account for you.
    `,
  },
  {
    title: 'Closing your account',
    content: `
    When you submit a request to close your Slyzed account, we'll get back to you within 48Hrs.
    Closing an account can take up to 60 days from the date we receive a request so that any outstanding sales, purchases or payments can be completed, and so we can meet our platform terms and conditions.
    `,
  },
];

export const authenticationManagementAccordionData = [
  {
    title: 'How do I create an account?',
    content: `
    You must be at least 18 Years old to create a Slyzed account
    Slyzed has made it easier for users to log in or sign up to our social commerce platform.
    Users can create accounts and directly sign up/log in to Slyzed using their Google, Facebook, Twitter or Microsoft account.
    `
  },
  {
    title: 'I forgot my password',
    content: `
    You can reset your password by using Forgot password self-service capability available on our platform.
    Simply follow the Forgot password link on the sign in page to start the recovery process
    `,
  },   
];

export const buyingAccordionData = [
  { 
    title: 'Buying on Slyzed', 
    content: `
    Slyzed offers an innovative platform to sell and buy products and make money from the value of products or services sold.
    Selling is no longer about getting a product and bringing it to market, it is convergence of social media technology and involvement of the community in the sales process.
    Slyzed offers social ecommerce features for brands and retailers to register and add interactive, shareable and immersive products and services creating new ways of connecting with customers, through our innovative technology that converges the power of social media technology in the sales process.
    The platform has 3 main users:<br /></b>
    • Seller: creates an account then registers and opens an online shop on the platform. Then add products with a selling and a slightly higher reseller price, ready for resellers to share them on their pages and resell them to consumers.
   
    • Resellers: Opens and creates an account, then clicks the follow button to follow the seller's online shop and gain access to their products before they can share them on their wall for reselling.
      Resellers will then resell the products and once sold they keep the difference (commission fee) between the selling price and the resale price.
 
    • Buyer: Opens and creates an account, to be able to purchase products and services from the platform.
   
    All users (a BUYER, SELLER or RESELLER) are  able to buy goods and services on the platform.
   
    When a buyer logs in they can navigate to the explore tab on the left menu and click on it. You will get two tabs named trending and recommended. The trending tab has the products which are being bought frequently while the recommended tab will display products based on your buying history.
   
    To buy a product click on the product, you will get a popup with product information including the product name, rating, and price among others. To buy the product you click on buy now
   
    Click on buy now you get a notification that the product has been added to the shopping basket.
    You have the option to Browse for more products and continue with your shopping or pay for your item.
   
    You will be directed to your basket where you can pay for your product via credit/debit card or through mobile money payments (such as M-PESA) depending on the country of the product listed.
    • Enter a shipping address
    • Choose a payment method and Place Your Order.
    ` 
  },
  { 
    title: 'Payments and charges', 
    content: `
    Slyzed accepts a variety of payment options, including credit, debit cards and mobile payments in selected countries.
    The platform includes payment methods you can use for buying goods and services. Once you're done with your shopping simply go to the checkout. You'll be able to select your payment method and review your order details, including your delivery address, before you pay.
    You can add a completely new payment card or change your existing payment details, before placing your order.
    Our payment gateway is powered by stripe.  https://stripe.com/gb/connect-account/legal
    Cheques, money orders, and bank wire transfers aren't allowed.   
    ` 
  },
  { 
    title: 'Search for products or services', 
    content: `
    To search for products or services on slyzed use our search bar at the top of most pages. Type in what you're looking for, hit Search, and we'll find the items that best match your search.
    You can also use filters to filter down the search list to show a smaller subset of the products. Filtering your product list can help you find the specific products you're looking for.
    Users can create accounts and directly sign up/log in to Slyzed using their Google, Facebook, Twitter or Microsoft account.
    ` 
  },
  { 
    title: 'Cancel an order', 
    content: `
    You can cancel an order within the eligibility period by using the Cancel order capability within the 'My Orders' section.
    Please contact us if you need further details.
    ` 
  },
  { 
    title: 'Return Policies', 
    content: `
    Please contact us for further details regarding return policies.
    ` 
  },
  { 
    title: 'Refunds', 
    content: `
    At slyzed we will issue a refund, for a product shipped through us within a maximum of 15 days and in some cases this may take longer than expected. In such cases contact us for more details.
    ` 
  },
  { 
    title: 'Delivery, order and returns', 
    content: `
    See our Return Policies
    ` 
  },
  { 
    title: 'Closing an account', 
    content: `
    Closing an account can take up to 60 days from the date we receive a request so that any outstanding sales, purchases or payments can be completed, and so we can meet our platform terms and conditions.
    ` 
  },
  { 
    title: 'Invoices, Order History', 
    content: `
    You can find all your orders in your My orders history. It's also the place to go if you need to view your current order details, cancelled orders, print your invoices, return an item and view shipping progress of your order.    
    ` 
  },
  { 
    title: 'Follow', 
    content: `
    As a buyer the first time you login to Slyzed you need to follow friends and family relatives who are reselling products on slyzed platform.
    From your slyzed account click Follow to subscribe to their Selling feeds and see the items they are selling on your timeline.
    The platform also suggests other accounts for you to follow based on your interests. Click on the suggestions tab to view the accounts we have recommended you to follow and to start seeing the items in their shop.
    ` 
  },
  { 
    title: 'Explore', 
    content: `
    To buy on Slyzed, you can also hover over the explore button to view products and services that are trending.
    When you find an item you want, click on it to select it.
    Review the item details, and click Buy now
    The item is Added to the basket. You can Click Go to basket or Browse for more products or services to buy
    When ready to checkout, follow Checkout link to Enter a shipping address
    Choose a payment method and Place Your Order.        
    ` 
  },
  { 
    title: 'Slyzed Notifications', 
    content: `
    From your Slyzed account click notifications to view any notification messages that help you increase engagement on your profile. 
    ` 
  },
  { 
    title: 'Sending and receiving messages', 
    content: `
    From your Slyzed account click messages to communicate and send direct messages to other users on the platform and even to ask about the products and services. All conversations are expected to comply with our Terms of use.
    ` 
  },
  { 
    title: 'My Favourites', 
    content: `
    From your Slyzed account click favourite to view your saved favourite products and services and your browsing history.
    It is also a convenient feature that speeds up the ordering process by having your favourite items at your fingertips.  
    ` 
  },
  { 
    title: 'My Profile', 
    content: `
    As a buyer your Slyzed profile allows you to share a little about yourself.  You can also upgrade your account and:
    • Become a reseller by upgrading your account to a reseller account and start making money reselling goods and services.
    • Create your shop now and start selling
    • You can also customise your account by adding custom content to your profile, including profile images. 
    ` 
  },
  { 
    title: 'More', 
    content: `
    Click more to access the setting page and help aand support links. 
    •	Settings
    •	Help
    `
  },
];

export const resellingAccordionData = [
  { 
    title: 'How do I resell on Slyzed', 
    content: `
    Resellers: Opens and creates an account, then clicks the follow button to follow the seller's online shop and gain access to their products before they can share them on their wall for reselling.    Resellers will then resell the products and once sold they keep the difference (commission fee) between the selling price and the resale price.    
    The platform includes payment methods you can use for buying goods and services. Once you're done with your shopping simply go to the checkout. You'll be able to select your payment method and review your order details, including your delivery address, before you pay.
    ` 
  },
  { 
    title: 'Signing up on Slyzed?', 
    content: `
    Slyzed has made it easier for users to log in or sign up to our social commerce platform. 
    Users can create accounts and directly sign up/log in to Slyzed using their Google, Facebook, Twitter or Microsoft account.
    ` 
  },
  { 
    title: 'Upgrading your account to become a reseller', 
    content: `
    Sign up to your basic account as a buyer
    As a buyer You can upgrade your account and become a reseller by upgrading your account to a reseller account and start making money reselling goods and services.
    You can also customise your account by adding custom content to your profile, including profile images. 
    ` 
  },
  { 
    title: 'Adding details to your shop', 
    content: `
    From My profile you customise your account by adding custom content to your profile, including profile images.
    ` 
  },
  { 
    title: 'Adding products to your shop', 
    content: `
    Once you upgrade your account to become a reseller on slyzed, you need to follow sellers who have allowed their products to be resold on commission.
    Resellers will then resell the products and once sold they keep the difference (commission fee) between the selling price and the resale price.
    From your slyzed account click follow to subscribe to the sellers channel in order to see the items they are selling on your timeline.
    The platform also suggests other accounts for you to follow based on your interests. Click on the suggestions tab to view the accounts we have recommended you to follow and to start seeing the items in their shop.
    You can also unfollow sellers accounts by simply clicking on the following button.
    You can also use the search bar to search for other sellers to follow or unfollow.  
    ` 
  },
  { 
    title: 'Making money on Slyzed', 
    content: `
    • Resellers will then resell the products and once sold they keep the difference (commission fee) between the selling price and the resale price.
    • The amount of money a reseller can make is calculated as the difference between the seller's selling price and the final reselling price set by the seller.
    • Seller: creates an account then registers and opens an online shop on the platform. Then add products with a selling and a slightly higher reseller price, ready for resellers to share them on their pages and resell them to consumers.
    ` 
  },
  { 
    title: 'Refunds', 
    content: `
    When a product is sold and there is a dispute, the sales process is suspended/frozen until the dispute is resolved.  This means no sales commission can be paid until the dispute is resolved.
    If a refund has to be issued we will issue a refund, for a product shipped through us within a maximum of 15 days and in some cases this may take longer than expected. In such cases contact us for more details.    
    No sales commission can be paid when an order has been cancelled.
    ` 
  },
  { 
    title: 'How long does it take to get my sales commission', 
    content: `
    When a product has been sold, we charge a total of 3% from the total reseller commission.  
    The total amount is visible in the reseller account but cannot be withdrawn for a maximum of 15 days and in some cases this may take longer than expected.
    In such cases contact us for more details.    
    No sales commission can be paid when an order has been cancelled.     
    ` 
  },
  { 
    title: 'Invoices', 
    content: `
    You'll find all your orders from the past 3 years in your purchase history. It's also the place to go if you need to return an item, pay for your purchases, or view your order details.
    Closing an account can take up to 60 days from the date we receive a request so that any outstanding sales, purchases or payments can be completed, and so we can meet our platform terms and conditions.
    ` 
  },
  { 
    title: 'Order History', 
    content: `
    You can find all your orders in your My orders history. It's also the place to go if you need to view your current order details, cancelled orders, print your invoices, return an item and view shipping progress of your order.
    ` 
  },
  { 
    title: 'Follow', 
    content: `
    As a buyer the first time you login to Slyzed you need to follow friends and family relatives who are reselling products on slyzed platform.
    From your slyzed account click Follow to subscribe to their Selling feeds and see the items they are selling on your timeline.
    The platform also suggests other accounts for you to follow based on your interests. Click on the suggestions tab to view the accounts we have recommended you to follow and to start seeing the items in their shop. 
    ` 
  },
  { 
    title: 'Explore', 
    content: `
    To buy on Slyzed, you can also hover over the explore button to view products and services that are trending.
    When you find an item you want, click on it to select it.
    Review the item details, and click Buy now
    The item is Added to the basket. You can Click Go to basket or Browse for more products or services to buy
    When ready to checkout, follow Checkout link to Enter a shipping address
    Choose a payment method and Place Your Order.        
    ` 
  },
  { 
    title: 'Slyzed Notifications', 
    content: `
    From your Slyzed account click notifications to view any notification messages that help you increase engagement on your profile. 
    ` 
  },
  { 
    title: 'Sending and receiving messages', 
    content: `
    From your Slyzed account click messages to communicate and send direct messages to other users on the platform and even to ask about the products and services. All conversations are expected to comply with our Terms of use.
    ` 
  },
  { 
    title: 'My Favourites', 
    content: `
    From your Slyzed account click favourite to view your saved favourite products and services and your browsing history.
    It is also a convenient feature that speeds up the ordering process by having your favourite items at your fingertips.  
    ` 
  },
  { 
    title: 'My Profile', 
    content: `
    As a buyer your Slyzed profile allows you to share a little about yourself.  You can also upgrade your account and:
    • Become a reseller by upgrading your account to a reseller account and start making money reselling goods and services.
    • Create your shop now and start selling
    • You can also customise your account by adding custom content to your profile, including profile images.
  ` 
  },
  { 
    title: 'More', 
    content: `
    Click more to access the setting page and help files. 
    •	Settings
    •	Help
    `
  },
];

export const sellingAccordionData = [
  { 
    title: 'How do I sell on Slyzed', 
    content: `
    Go to https://app.slyzed.com and click the sign in or sign up button.
    Slyzed has made it easier for users to log in or sign up to our social commerce platform.
    Users can create accounts and directly sign up/log in to Slyzed using their Google, Facebook, Twitter or Microsoft account.
   
    Seller creates an account then registers and opens an online shop on the platform. Then add products with a selling and a slightly higher reseller price, ready for resellers to share them on their pages and resell them to consumers.
    Once you have signed up, your default account allows you to buy goods and services on the platform.
 
    You become a seller by upgrading your account to a seller account and start selling goods and services on the platform.  
    You can also customise your account by adding custom content to your profile, including profile images.
  ` 
  },
  {
    title: 'Upgrading your account to a seller.',
    content: `
    Sign up to your basic account as a buyer
    Once you have signed up your default account allows you to buy goods and services on the platform, from there you can also upgrade your account and become a seller and start selling on the platform.
    `
  },
  { 
    title: 'Setting up your store', 
    content: `
    From my profile upgrade your buyer account to create a shop and become a seller.
    • Add the name and description of your shop.
    • Add the shop address postcode and country.
    • Update the details and submit them for approval from the slyzed team.
    • Once verified and approved a confirmation email will be sent out to you to confirm your shop has been opened. This process normally takes 2-3 days.
    • Should we need further clarification we will contact you by email or telephone     
    ` 
  },
  { 
    title: 'Adding details to your shop', 
    content: `
    From My profile you customise your account by adding custom content to your profile, including profile images. 
  ` 
  },
  { 
    title: 'Adding products and services to your shop', 
    content: `
    Once your shop has been approved you can start selling products and services on the platform.
    Click on My shop menu
    Click Add new listing to open a new window where you can add a product or service.
    • Select the listing type:
    • Product or Service
    • Add the title of the item
    • Select the currency
    • Add the listing price - This is the selling price that seller wishes to recover from the sale of the goods or services
    • Add the Reseller price - This is the final selling price of the goods and services. Having a reseller price allows for resellers to market your goods and services and make some money.  The difference between the listing price and the resale price is what the reseller keeps as commission (subject to platform features)
    • Add item to a category
    • Add stock keeping unit (SKU)
    • Add stock count
    • Add product description
    • Add product images
    • Do you want to share this product Select Yes.   This unique feature allows resellers to follow you as a seller and view the products you have for sale.
 
    • Resellers will then resell the products and once sold they keep the difference (commission fee) between the selling price and the resale price.
    This helps to create engagement, promote products to a bigger audience and help resellers make money promoting your goods and services.
    A product or service can be shared by many resellers but it is the one who makes the sale that gets the commission.
    Once the stock count is 0 (zero) nothing can be sold or resold.

    ` 
  },
  { 
    title: 'Refunds', 
    content:`
    When a product is sold and there is a dispute, the sales process is suspended/frozen until the dispute is resolved.  This means no money can be paid until the dispute is resolved.
    If a refund has to be issued we will issue a refund, for a product shipped through us within a maximum of 15 days and in some cases this may take longer than expected. In such cases contact us for more details.    
    No sales commission can be paid to the reseller when an order has been cancelled. 
  ` 
  },
  { 
    title: 'How long does it take to get my money', 
    content: `
    When a product has been sold, we charge a total of 3% from the total sale of the product. (listing fee)  
    The sales amount is visible on the seller account but cannot be withdrawn for a maximum of 15 days and in some cases this may take longer than expected.
    In such cases contact us for more details.    
    No sales commission can be paid when an order is in dispute or has been cancelled
    ` 
  },
  { 
    title: 'My Shop', 
    content: `
    From My Shop You'll find the full list of all the items in your shop.
    • Click on the Selling tab to view all the products and services for sale.  You can then add, edit,update and delete the items in your shop.
    • Click on incoming orders to view all your incoming orders.  You can find all your orders in your My orders history. It's also the place to go if you need to view your current order details, cancelled orders, print your invoices, return an item and view shipping progress of your order.
    • Click on my orders to view all the orders you have made on the shop. It's also the place to go if you need to return an item, pay for your purchases, or view your order details.
    • Closing an account can take up to 60 days from the date we receive a request so that any outstanding sales, purchases or payments can be completed, and so we can meet our platform terms and conditions.
    • Click on reviews to views all your reviews</b>
    • Closing an account can take up to 60 days from the date we receive a request so that any outstanding sales, purchases or payments can be completed, and so we can meet our platform terms and conditions.
  ` 
  },
  { 
    title: 'Follow others', 
    content: `
    As a seller the first time you login to slyzed you can follow other Reseller friends and family relatives who can Resell your goods and services on the platform.
    From your Slyzed account click Follow to subscribe to their selling channel and see the items they are selling on your timeline feed.
    The platform also recommends other accounts for you to follow based on your interests. Click on the suggestions tab to view the accounts we have recommended for you.
  ` 
  },
  { 
    title: 'Explore', 
    content: `
    To buy on Slyzed, you can also hover over the explore button to view products and services that are trending.
    When you find an item you want, click on it to select it.
    Review the item details, and click Buy now
    The item is Added to the basket. You can Click Go to basket or Browse for more products or services to buy
    When ready to checkout, follow Checkout link to Enter a shipping address
    Choose a payment method and Place Your Order.        
    ` 
  },
  { 
    title: 'Slyzed Notifications', 
    content: `
    From your Slyzed account click notifications to view any notification messages that help you increase engagement on your profile. 
  ` 
  },
  { 
    title: 'Sending and receiving messages', 
    content: `
    From your Slyzed account click messages to communicate and send direct messages to other users on the platform and even to ask about the products and services. All conversations are expected to comply with our Terms of use.
  ` 
  },
  { 
    title: 'My Favourites', 
    content: `
    From your Slyzed account click favourite to view your saved favourite products and services and your browsing history.
    It is also a convenient feature that speeds up the ordering process by having your favourite items at your fingertips.  
  ` 
  },
  { 
    title: 'My Profile', 
    content: `
    As a buyer your Slyzed profile allows you to share a little about yourself.  You can also upgrade your account and:
    • Become a reseller by upgrading your account to a reseller account and start making money reselling goods and services.
    • Create your shop now and start selling
    • You can also customise your account by adding custom content to your profile, including profile images. 
  ` 
  },
  { 
    title: 'More', 
    content: `
    Click more to access the setting page and help and support links. 
    •	Settings
    •	Help
    `
  },
];
