import React from 'react';
// import sections
import Hero from '../components/sections/Hero';
import About from '../components/sections/About';
import Team from '../components/sections/Team';
import Partners from '../components/sections/Partners';
import MailchimpManual from '../components/sections/MailchimpManual';

const Home = () => {
  return (
    <>
      <Hero />
      <About id='about' />
      <Team id='team' invertMobile topDivider />
      <Partners id='partners' topDivider />
      <MailchimpManual />
    </>
  );
};

export default Home;
