import React from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import Image from '../../elements/Image';

const Logo = ({ className, ...props }) => {
  const classes = classNames('brand', className);

  return (
    <div {...props} className={classes}>
      <h1 className='m-0'>
        <Link to='/'>
          <Image
            src='https://res.cloudinary.com/slyzed/image/upload/v1654461294/slyzed-platform/slyzed-ecommerce-logo-circle.png'
            alt='Open'
            width={32}
            height={32}
            className='no-margin'
          />
        </Link>
      </h1>
    </div>
  );
};

export default Logo;
