import React from 'react';
import classNames from 'classnames';
import { FaFacebook, FaInstagram, FaTwitter } from 'react-icons/fa';

const FooterSocial = ({ className, ...props }) => {
  const classes = classNames('footer-social', className);

  return (
    <div {...props} className={classes}>
      <ul className='list-reset'>
        <li>
          <a href={process.env.REACT_APP_SLYZAPP_FACEBOOK_URL}>
            <FaFacebook size={20} />
          </a>
        </li>
        <li>
          <a href={process.env.REACT_APP_SLYZAPP_TWITTER_URL}>
            <FaTwitter size={20} />
          </a>
        </li>
        <li>
          <a href={process.env.REACT_APP_INSTAGRAM_TWITTER_URL}>
            <FaInstagram size={20} />
          </a>
        </li>
      </ul>
    </div>
  );
};

export default FooterSocial;
