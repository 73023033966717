import classNames from 'classnames';
import React from 'react';
import { Link } from 'react-router-dom';
import { SectionTilesProps } from '../../utils/SectionProps';
import Image from '../elements/Image';

const propTypes = {
  ...SectionTilesProps.types,
};

const defaultProps = {
  ...SectionTilesProps.defaults,
};

const SupportTile = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  pushLeft,
  ...props
}) => {
  const outerClasses = classNames(
    'support-tile section',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'support-tile-inner ',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  const tilesClasses = classNames('tiles-wrap', pushLeft && 'push-left');

  return (
    <section {...props} className={outerClasses}>
      <div className='container p-16'>
        <div className={innerClasses}>
          <div className={tilesClasses}>
            <Link className='help-icon' to='/what-is-slyzed'>
              <div
                className='tiles-item reveal-from-right'
                data-reveal-delay='200'
              >
                <div className='tiles-item-inner'>
                  <div className='support-tile-item-content'>
                    <Image
                      src='https://res.cloudinary.com/slyzed/image/upload/v1659049250/slyzed-platform/slyzed-ecommerce-logo-square.png'
                      alt='Features split 02'
                      width={140}
                      height={140}
                      className='rounded-corners'
                    />{' '}
                  </div>
                  <div className='support-tile-item-footer text-xs mt-32 mb-0'>
                    <p className='support-tile-item-name text-color-secondary'>
                      What is Slyzed
                    </p>
                    <p className='support-tile-item-name text-color-primary'>
                      Explore what the Slyzed platform has to offer.
                    </p>
                  </div>
                </div>
              </div>
            </Link>

            <Link
              className='help-icon'
              to='/creating-and-managing-your-account'
            >
              <div className='tiles-item reveal-from-bottom'>
                <div className='tiles-item-inner'>
                  <div className='support-tile-item-content'>
                    <Image
                      src={require('../../assets/image/account.png')}
                      alt='Features split 02'
                      width={140}
                      height={140}
                      className='rounded-corners'
                    />{' '}
                  </div>
                  <div className='support-tile-item-footer text-xs mt-32 mb-0'>
                    <p className='support-tile-item-name text-color-secondary'>
                      Account Management
                    </p>
                    <p className='support-tile-item-name text-color-primary'>
                      Get assistance on managing your Slyzed platform account
                      and explore options on how to setup your new Slyzed
                      account.
                    </p>
                  </div>
                </div>
              </div>
            </Link>
            <Link className='help-icon' to='/login-and-password'>
              <div
                className='tiles-item reveal-from-left'
                data-reveal-delay='200'
              >
                <div className='tiles-item-inner'>
                  <div className='support-tile-item-content'>
                    <Image
                      src={require('../../assets/image/password.png')}
                      alt='Features split 02'
                      width={140}
                      height={140}
                      className='rounded-corners'
                    />{' '}
                  </div>
                  <div className='support-tile-item-footer text-xs mt-32 mb-0'>
                    <p className='support-tile-item-name text-color-secondary'>
                      Login and Password
                    </p>
                    <p className='support-tile-item-name text-color-primary'>
                      Keep your account secure, explore account recovery options
                      and password management.
                    </p>
                  </div>
                </div>
              </div>
            </Link>
            <Link className='help-icon' to='/buying-on-slyzed'>
              <div
                className='tiles-item reveal-from-left'
                data-reveal-delay='200'
              >
                <div className='tiles-item-inner'>
                  <div className='support-tile-item-content'>
                    <Image
                      src={require('../../assets/image/buy.png')}
                      alt='Features split 02'
                      width={140}
                      height={140}
                      className='rounded-corners'
                    />{' '}
                  </div>
                  <div className='support-tile-item-footer text-xs mt-32 mb-0'>
                    <p className='support-tile-item-name text-color-secondary'>
                      Buying on Slyzed
                    </p>
                    <p className='support-tile-item-name text-color-primary'>
                      Get the most out of your Buyer account on the Slyzed
                      platform
                    </p>
                  </div>
                </div>
              </div>
            </Link>
            <Link className='help-icon' to='/reselling-on-slyzed'>
              <div
                className='tiles-item reveal-from-left'
                data-reveal-delay='200'
              >
                <div className='tiles-item-inner'>
                  <div className='support-tile-item-content'>
                    <Image
                      src={require('../../assets/image/resell.png')}
                      alt='Features split 02'
                      width={140}
                      height={140}
                      className='rounded-corners'
                    />{' '}
                  </div>
                  <div className='support-tile-item-footer text-xs mt-32 mb-0'>
                    <p className='support-tile-item-name text-color-secondary'>
                      Reselling on Slyzed
                    </p>
                    <p className='support-tile-item-name text-color-primary'>
                      Get your questions answered as a Reseller!
                    </p>
                  </div>
                </div>
              </div>
            </Link>
            <Link className='help-icon' to='/selling-on-slyzed'>
              <div
                className='tiles-item reveal-from-left'
                data-reveal-delay='200'
              >
                <div className='tiles-item-inner'>
                  <div className='support-tile-item-content'>
                    <Image
                      src={require('../../assets/image/sell.png')}
                      alt='Features split 02'
                      width={140}
                      height={140}
                      className='rounded-corners'
                    />{' '}
                  </div>
                  <div className='support-tile-item-footer text-xs mt-32 mb-0'>
                    <p className='support-tile-item-name text-color-secondary'>
                      Selling on Slyzed
                    </p>
                    <p className='support-tile-item-name text-color-primary'>
                      Find support for selling goods and services on the Slyzed
                      platform.
                    </p>
                  </div>
                </div>
              </div>
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
};

SupportTile.propTypes = propTypes;
SupportTile.defaultProps = defaultProps;

export default SupportTile;
