import React from 'react';
import { Helmet } from 'react-helmet';
import Button from '../components/elements/Button';
import classNames from 'classnames';
import ButtonGroup from '../components/elements/ButtonGroup';

const My404Component = (
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  ...props
) => {
  const outerClasses = classNames(
    'hero section center-content',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'hero-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );
  return (
    <>
      <Helmet>
        <title>Slyzed - 404</title>
        <meta
          name='description'
          content='Slyzed offers an innovative social e-commerce platform to sell, re-sell and buy products and services'
        />
      </Helmet>
      <section {...props} className={outerClasses}>
        <div className='container-sm'>
          <div className={innerClasses}>
            <div className='hero-content'>
              <h1
                className='mt-0 mb-16 reveal-from-bottom'
                color='primary'
                data-reveal-delay='200'
              >
                Ooops... we've encounted an error
              </h1>
              <div className='container-xs'>
                <p
                  className='m-0 mb-32 reveal-from-bottom'
                  data-reveal-delay='400'
                >
                  Let's return home!
                </p>
                <div className='reveal-from-bottom' data-reveal-delay='600'>
                  <ButtonGroup>
                    <Button
                      tag='a'
                      color='primary'
                      wideMobile
                      href={process.env.REACT_APP_SLYZAPP_URL}
                    >
                      Launch Slyzed Platform
                    </Button>
                    <Button tag='a' color='secondary' wideMobile href='/'>
                      Slyzed Home
                    </Button>
                    <Button tag='a' color='secondary' wideMobile href='/help'>
                      Help Center
                    </Button>
                  </ButtonGroup>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default My404Component;
