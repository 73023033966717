import React from 'react';
import classNames from 'classnames';
import { SectionProps } from '../../utils/SectionProps';
import ButtonGroup from '../elements/ButtonGroup';
import Button from '../elements/Button';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
const propTypes = {
  ...SectionProps.types,
};

const defaultProps = {
  ...SectionProps.defaults,
};

const Hero = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  ...props
}) => {
  const outerClasses = classNames(
    'hero section center-content',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'hero-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  return (
    <section {...props} className={outerClasses}>
      <div className='container-sm'>
        <div className={innerClasses}>
          <div className='hero-content'>
            <h1
              className='mt-0 mb-16 reveal-from-bottom'
              color='primary'
              data-reveal-delay='200'
            >
              Slyzed
            </h1>
            <div className='container-xs'>
              <p
                className='m-0 mb-32 ta-c reveal-from-bottom'
                data-reveal-delay='400'
              >
                Everyone has a share
              </p>
              <div className='reveal-from-bottom' data-reveal-delay='600'>
                <ButtonGroup>
                  <Button
                    tag='a'
                    color='primary'
                    wideMobile
                    href={process.env.REACT_APP_SLYZAPP_URL}
                  >
                    Get started
                  </Button>
                  <Button tag='a' color='secondary' wideMobile href='/help'>
                    Help & Support
                  </Button>
                </ButtonGroup>
              </div>
            </div>
          </div>
          <div
            className='hero-figure reveal-from-bottom'
            data-reveal-value='20px'
            data-reveal-delay='800'
          >
            {/* <Carousel /> */}
            <iframe
              src='https://www.youtube.com/embed/JIbY6215vqU?si=Yb0g12h2s2BJLjXF'
              frameBorder='0'
              allow='autoplay; encrypted-media'
              allowFullScreen
              width='90%'
              height={500}
              title='about slyzed'
            />
          </div>
        </div>
      </div>
    </section>
  );
};

Hero.propTypes = propTypes;
Hero.defaultProps = defaultProps;

export default Hero;
