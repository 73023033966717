import React from 'react';
import classNames from 'classnames';
import { SectionSplitProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import Image from '../elements/Image';

const propTypes = {
  ...SectionSplitProps.types,
};

const defaultProps = {
  ...SectionSplitProps.defaults,
};

const Team = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  invertMobile,
  invertDesktop,
  alignTop,
  imageFill,
  pushLeft,
  ...props
}) => {
  const outerClasses = classNames(
    'features-split section',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'features-split-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  const tilesClasses = classNames('tiles-wrap', pushLeft && 'push-left');

  const sectionHeader = {
    title: 'Meet the Team',
    paragraph: `Slyzed is currently led by co-founders Desmond Nganga and Julius Angwenyi. We have commercial work experience in Project Management and Software Engineering/IT industry over the last 20 years.
    We have both worked at different levels in the IT industry, thus gained in-depth working knowledge of Software delivery, Project Management, creating and managing teams of various sizes as well as experience in management and running of business operations.
    
    Our ambitious and diverse team has decades of experience and is passionate about continuously improving the Slyzed platform.
    
    We are using technology to save the planet, while building strong businesses that can scale without depleting the planet’s resources.  As a result customers can enjoy what the world has to offer while still being mindful and aware of the environment.
    
    
    Through Slyzed conscious shoppers can find eco-friendly products from vetted sellers, who are looking to increase their customers base,  scale their business, expand visibility and improve sustainability. We have designed an innovative social e-commerce platform that offers new ways of buying and selling goods and services through resellers.
    
    The platform helps resellers generate a regular income and business to reach new audiences, achieve growth and success and radically change lives in the modern digital economy.    
      `,
  };

  return (
    <section {...props} className={outerClasses}>
      <div className='container'>
        <div className={innerClasses}>
          <div className={tilesClasses}>
            <SectionHeader data={sectionHeader} className='center-content' />

            <div
              className='tiles-item reveal-from-left'
              data-reveal-delay='200'
            >
              <div className='tiles-item-inner'>
                <div className='support-tile-item-content light-background rounded-corners'>
                  <Image
                    src='https://res.cloudinary.com/slyzed/image/upload/v1659381744/slyzed-dot-com/desmond-nganga-slyzed-cofounder.jpg'
                    alt='Desmond Nganga profile image'
                    width={150}
                    height={150}
                    className={'circular-border'}
                  />{' '}
                <div className='support-tile-item-footer text-xs p-16 mb-0'>
                  <span className='support-tile-item-name text-color-secondary'>
                    Desmond Nganga | Co-Founder
                  </span>
                </div>                 
                </div>
              </div>
            </div>
            <div
              className='tiles-item reveal-from-left'
              data-reveal-delay='200'
            >
              <div className='tiles-item-inner'>
                <div className='support-tile-item-content light-background rounded-corners'>
                  <Image
                    src='https://res.cloudinary.com/slyzed/image/upload/v1659381744/slyzed-dot-com/julius-angwenyi-slyzed-cofounder.jpg'
                    alt='Julius Angwenyi profile image'
                    width={150}
                    height={150}
                    className={'circular-border'}
                  />{' '}
                <div className='support-tile-item-footer text-xs p-16 mb-0'>
                  <span className='support-tile-item-name text-color-secondary'>
                    Julius Angwenyi | Co-Founder
                  </span>
                </div>                
                </div>
              </div>
            </div>

            <div
              className='tiles-item reveal-from-left'
              data-reveal-delay='200'
            >
              <div className='tiles-item-inner'>
                <div className='support-tile-item-content light-background rounded-corners'>
                  <Image
                    src='https://res.cloudinary.com/slyzed/image/upload/v1659383129/slyzed-dot-com/lui-morara-slyzed-strategist.jpg'
                    alt='Lui Morara profile image'
                    width={150}
                    height={150}
                    className={'circular-border'}
                  />{' '}
                <div className='support-tile-item-footer text-xs p-16 mb-0'>
                  <span className='support-tile-item-name text-color-secondary'>
                    Lui Morara | Innovation
                  </span>
                </div>                
                </div>
              </div>
            </div>

            <div
              className='tiles-item reveal-from-left'
              data-reveal-delay='200'
            >
              <div className='tiles-item-inner'>
                <div className='support-tile-item-content light-background rounded-corners'>
                  <Image
                    src='https://res.cloudinary.com/slyzed/image/upload/v1659384097/slyzed-dot-com/henry-njogu-slyzed-business-lead.png'
                    alt='Henry Njogu profile image'
                    width={150}
                    height={150}
                    className={'circular-border'}
                  />{' '}
                <div className='support-tile-item-footer text-xs p-16 mb-0'>
                  <span className='support-tile-item-name text-color-secondary'>
                    Henry Njogu | Business
                  </span>
                </div>                
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </section>
  );
};

Team.propTypes = propTypes;
Team.defaultProps = defaultProps;

export default Team;
