import React from 'react';
// import sections
import HelpHero from '../components/sections/help-support-sections/HelpHero';
import SupportTile from '../components/sections/SupportTile';
import { Helmet } from 'react-helmet';

const HelpHome = () => {
  return (
    <>
      <Helmet>
        <title>Slyzed - Help Center</title>
        <meta
          name='description'
          content='Slyzed offers an innovative social e-commerce platform to sell, re-sell and buy products and services'
        />
      </Helmet>
      <HelpHero />
      <SupportTile />
    </>
  );
};

export default HelpHome;
