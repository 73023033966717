import React from 'react';
import PropTypes from 'prop-types';
import jsonp from 'jsonp';
import toQueryString from 'to-querystring';
import SimpleForm from './SimpleForm';

const slyzedUrl =
  'https://slyzed.us6.list-manage.com/subscribe/post?u=19ee1785e5585235d0e6cdf57&id=49f277dd8d';

const getAjaxUrl = (slyzedUrl) => slyzedUrl.replace('/post?', '/post-json?');

class MailchimpManual extends React.Component {
  state = {
    status: null,
    message: null,
  };
  subscribe = (data) => {
    const params = toQueryString(data);
    const url = getAjaxUrl(slyzedUrl) + '&' + params;
    this.setState(
      {
        status: 'sending',
        message: null,
      },
      () =>
        jsonp(
          url,
          {
            param: 'c',
          },
          (err, data) => {
            if (err) {
              this.setState({
                status: 'error',
                message: err,
              });
            } else if (data.result !== 'success') {
              this.setState({
                status: 'error',
                message: data.msg,
              });
            } else {
              this.setState({
                status: 'success',
                message: data.msg,
              });
            }
          }
        )
    );
  };
  render() {
    return this.props.render({
      subscribe: this.subscribe,
      status: this.state.status,
      message: this.state.message,
    });
  }
}

MailchimpManual.propTypes = {
  render: PropTypes.func,
};

MailchimpManual.defaultProps = {
  render: ({ subscribe, status, message }) => (
    <SimpleForm
      id='contact'
      status={status}
      message={message}
      onSubmitted={(formData) => subscribe(formData)}
    />
  ),
};

export default MailchimpManual;
