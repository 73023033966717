import React from 'react';
import classNames from 'classnames';
import { SectionTilesProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import Image from '../elements/Image';

const propTypes = {
  ...SectionTilesProps.types,
};

const defaultProps = {
  ...SectionTilesProps.defaults,
};
const About = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  pushLeft,
  imageFill,
  invertMobile,
  invertDesktop,
  alignTop,

  ...props
}) => {
  const outerClasses = classNames(
    'about section',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'about-inner section-inner pt-0',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  const splitClasses = classNames(
    'split-wrap',
    invertMobile && 'invert-mobile',
    invertDesktop && 'invert-desktop',
    alignTop && 'align-top'
  );

  const tilesClasses = classNames('tiles-wrap', pushLeft && 'push-left');

  const about = {
    title: 'About Slyzed',
    paragraph:
      'At Slyzed we leverage the power of technology and the concept of social media to create a new innovative, circular ecommerce platform help reduce waste and change the way we shop online.',
  };

  const ourCoreBusiness = {
    title: 'Our core business',
    paragraph:`Essentially our core business is creating a sustainable and environmentally-friendly approach to consumption. By promoting the reuse and reselling of goods and services  to reduce waste and conserves natural resources.

    The economy of the future must meet the needs of a growing population within the confines of diminishing critical resources, supply chains made vulnerable by climate change and political instability, tightening environmental regulation and the urgent need to reduce greenhouse gas emissions to net zero.
    
    Delivering a resilient economy in the will require, innovation towards a circular economy and strong collaboration between government and business – to ensure a commitment to try new ways of doing things. Businesses must also look for ways to support the communities they serve.
    
    With the cost of living is increasing at an alarming rate an innovative part of the platform is to offer resellers the opportunity to resell goods and services and make money. Through Slyzed conscious shoppers can find eco-friendly products from vetted sellers, who are selling through resellers.  Our business model works well for sellers who are looking to increase their customer’s base, scale their business, expand visibility and improve sustainability.
    
    
    By providing opportunities for resellers to earn money, the circular reselling economy encourages a shift towards more sustainable consumption patterns. Consumers are incentivized to prolong the life of their belongings by repairing, refurbishing, or reselling them, rather than simply discarding them and purchasing new products. This can lead to a reduction in waste and an increase in resource efficiency, which benefits both the environment and the economy.    
    
    `,
  };

  const aboutHelp = {
    title: 'We help you',
    paragraph: '',
  };

  const powerOfNetwork = {
    title: 'The solution - power of network',
    paragraph: '',
  };

  const ecommerce = {
    title: ' A new dawn for social e-commerce',
    paragraph: `We currently live in a linear economy where we take raw materials from the ground and turn them into a product. Once that product is no longer needed or is broken, we throw it away. Our aim at Slyzed is to create a global sustainable, innovative, emerging, circular, buying, selling and reselling platform, for retailers committed to social and environmental values, this platform offers sellers a great opportunity to connect with conscious consumers, who are motivated more by sustainability. 

    With the cost of living is increasing at an alarming rate an innovative part of the platform is to offer resellers the opportunity to resell goods and services and make money.
    
    Consumers have become more sensitive to the environmental impact of their shopping decisions, Through Slyzed conscious shoppers can find eco-friendly products from vetted sellers, who are looking to increase their customers base,  scale their business, expand visibility and improve sustainability.    
      `,
  };

  return (
    <section {...props} className={outerClasses}>
      <div className='container'>
        <div className={innerClasses}>
          <SectionHeader data={about} className='center-content' />
          <SectionHeader
            data={ourCoreBusiness}
            containerSize={'m'}
            className='center-content'
          />
          <div className={splitClasses}>
            <SectionHeader
              data={powerOfNetwork}
              className='center-content pb-0 mt-16'
            />
            <div className='split-item'>
              <div className='container p-16'>
                <div className={innerClasses}>
                  <div className={tilesClasses}>
                    <div
                      className='tiles-item reveal-from-right'
                      data-reveal-delay='200'
                    >
                      <div className='tiles-item-inner'>
                        <div className='support-tile-item-content'>
                          <Image
                            src='https://res.cloudinary.com/slyzed/image/upload/v1680123839/slyzed-platform/slyzed-power-of-network-sellers.png'
                            alt='slyzed-power-of-network-sellers'
                            width={140}
                            height={140}
                            className='rounded-corners'
                          />{' '}
                        </div>
                        <div className='support-tile-item-footer text-xs mt-32 mb-0'>
                          <h4 className='center-content pb-0 mt-16'>1</h4>
                          <p className='center-content support-tile-item-name text-color-primary'>                            
                            1 seller selling 1 product or service
                          </p>
                        </div>
                      </div>
                    </div>
                    <div
                      className='tiles-item reveal-from-right'
                      data-reveal-delay='200'
                    >
                      <div className='tiles-item-inner'>
                        <div className='support-tile-item-content'>
                          <Image
                            src='https://res.cloudinary.com/slyzed/image/upload/v1680123839/slyzed-platform/slyzed-power-of-network-resellers.png'
                            alt='slyzed-power-of-network-sellers'
                            width={280}
                            height={280}
                            className='rounded-corners'
                          />{' '}
                        </div>
                        <div className='support-tile-item-footer text-xs mt-32 mb-0'>
                          <h4 className='center-content pb-0 mt-16'>20</h4>
                          <p className='center-content support-tile-item-name text-color-primary'>                            
                            1 seller has average of 20 resellers
                          </p>
                        </div>
                      </div>
                    </div>
                    <div
                      className='tiles-item reveal-from-right'
                      data-reveal-delay='200'
                    >
                      <div className='tiles-item-inner'>
                        <div className='support-tile-item-content'>
                          <Image
                            src='https://res.cloudinary.com/slyzed/image/upload/v1680123839/slyzed-platform/slyzed-power-of-network-buyers.png'
                            alt='slyzed-power-of-network-buyers'
                            width={140}
                            height={140}
                            className='rounded-corners'
                          />{' '}
                        </div>
                        <div className='support-tile-item-footer text-xs mt-32 mb-0'>
                          <h4 className='center-content pb-0 mt-16'>200</h4>
                          <p className='center-content support-tile-item-name text-color-primary'>
                            1 reseller with average of 10 buyers, will reach 200 buyers*
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>          
          <div className={splitClasses}>
            <SectionHeader
              data={aboutHelp}
              className='center-content pb-0 mt-16'
            />
            <div className='split-item'>
              <div className='container p-16'>
                <div className={innerClasses}>
                  <div className={tilesClasses}>
                    <div
                      className='tiles-item reveal-from-right'
                      data-reveal-delay='200'
                    >
                      <div className='tiles-item-inner'>
                        <div className='support-tile-item-content'>
                          <Image
                            src='https://res.cloudinary.com/slyzed/image/upload/v1654605089/slyzed-platform/slyzed-seller.png'
                            alt='slyzed-sellers'
                            width={140}
                            height={140}
                            className='rounded-corners'
                          />{' '}
                        </div>
                        <div className='support-tile-item-footer text-xs mt-32 mb-0'>
                          <p className='support-tile-item-name text-color-primary'>
                              Sell your goods and services quickly. Find the right influencers who are passionate about selling your product or service. Monitor your sales reports. Get positive reviews and win more customers.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div
                      className='tiles-item reveal-from-right'
                      data-reveal-delay='200'
                    >
                      <div className='tiles-item-inner'>
                        <div className='support-tile-item-content'>
                          <Image
                            src='https://res.cloudinary.com/slyzed/image/upload/v1654605089/slyzed-platform/slyzed-reseller.png'
                            alt='slyzed-resellers'
                            width={140}
                            height={140}
                            className='rounded-corners'
                          />{' '}
                        </div>
                        <div className='support-tile-item-footer text-xs mt-32 mb-0'>
                          <p className='support-tile-item-name text-color-primary'>
                            Establish new partnerships with sellers. Sell to network of friends and families Earn money and get timely payment Get real-time info on your earnings. Add/remove products and service, sell what is trending.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div
                      className='tiles-item reveal-from-right'
                      data-reveal-delay='200'
                    >
                      <div className='tiles-item-inner'>
                        <div className='support-tile-item-content'>
                          <Image
                            src='https://res.cloudinary.com/slyzed/image/upload/v1654605089/slyzed-platform/slyzed-buyer.png'
                            alt='slyzed-buyers'
                            width={140}
                            height={140}
                            className='rounded-corners'
                          />{' '}
                        </div>
                        <div className='support-tile-item-footer text-xs mt-32 mb-0'>
                          <p className='support-tile-item-name text-color-primary'>
                              Buy from and support friends and families to generate a regular income Buy from a Trusted, innovative and secure platform. Access our platform from any Device. Get trusted recommendations on what to buy.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <SectionHeader
            data={ecommerce}
            containerSize={'m'}
            className='center-content'
          />
        </div>
      </div>
    </section>
  );
};

About.propTypes = propTypes;
About.defaultProps = defaultProps;

export default About;
