import React, { useRef, useEffect } from 'react';
import { useLocation, Switch, Route } from 'react-router-dom';
import AppRoute from './utils/AppRoute';
import ScrollReveal from './utils/ScrollReveal';
import ReactGA from 'react-ga';

// Layouts
import LayoutDefault from './layouts/LayoutDefault';

// Views
import Home from './views/Home';
import HelpHome from './views/HelpHome';
import WhatIsSlyzed from './views/help-support/WhatIsSlyzed';
import AccountManagement from './views/help-support/AccountManagement';
import HelpLayout from './layouts/HelpLayout';
import AuthenticationManagement from './views/help-support/AuthenticationManagement';
import BuyingSupport from './views/help-support/BuyingSupport';
import ResellingSupport from './views/help-support/ResellingSupport';
import SellingSupport from './views/help-support/SellingSupport';
import My404Component from './views/My404Component';
import Privacy from './views/Privacy';
import Terms from './views/Terms';
import CookiePolicy from './views/CookiePolicy';

// Initialize Google Analytics
ReactGA.initialize('G-KP62M1VSWJ');

const trackPage = (page) => {
  ReactGA.set({ page });
  ReactGA.pageview(page);
};

const App = () => {
  const childRef = useRef();
  let location = useLocation();

  useEffect(() => {
    const page = location.pathname;
    document.body.classList.add('is-loaded');
    childRef.current.init();
    trackPage(page);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return (
    <ScrollReveal
      ref={childRef}
      children={() => (
        <Switch>
          <AppRoute exact path='/' component={Home} layout={LayoutDefault} />

          <AppRoute
            exact
            path='/help'
            component={HelpHome}
            layout={HelpLayout}
          />
          <AppRoute
            exact
            path='/what-is-slyzed'
            component={WhatIsSlyzed}
            layout={HelpLayout}
          />
          <AppRoute
            exact
            path='/creating-and-managing-your-account'
            component={AccountManagement}
            layout={HelpLayout}
          />
          <AppRoute
            exact
            path='/login-and-password'
            component={AuthenticationManagement}
            layout={HelpLayout}
          />
          <AppRoute
            exact
            path='/buying-on-slyzed'
            component={BuyingSupport}
            layout={HelpLayout}
          />
          <AppRoute
            exact
            path='/reselling-on-slyzed'
            component={ResellingSupport}
            layout={HelpLayout}
          />
          <AppRoute
            exact
            path='/selling-on-slyzed'
            component={SellingSupport}
            layout={HelpLayout}
          />

          <AppRoute
            exact
            path='/privacy-policy'
            component={Privacy}
            layout={HelpLayout}
          />
          <AppRoute exact path='/terms' component={Terms} layout={HelpLayout} />
          <AppRoute
            exact
            path='/cookie-policy'
            component={CookiePolicy}
            layout={HelpLayout}
          />

          <Route path='*' status={404} component={My404Component} />
        </Switch>
      )}
    />
  );
};

export default App;
