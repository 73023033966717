import React from 'react';
import classNames from 'classnames';
import Accordion from '../../components/elements/Accordion';
import { authenticationManagementAccordionData } from '../../assets/content/HelpContent';
import ButtonGroup from '../../components/elements/ButtonGroup';
import Button from '../../components/elements/Button';

const AuthenticationManagement = (
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  ...props
) => {
  const outerClasses = classNames(
    'support-tile section',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'support-tile-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  return (
    <>
      <>
        <section {...props} className={outerClasses}>
          <div className='container-sm'>
            <div className={innerClasses}>
              <div className='hero-content'>
                <h1
                  className='mt-0 mb-16 ta-c reveal-from-bottom'
                  color='primary'
                  data-reveal-delay='200'
                >
                  Login and Password
                </h1>
                <div className='container-xs'>
                  <p
                    className='m-0 mb-32 ta-c reveal-from-bottom'
                    data-reveal-delay='400'
                  >
                    Manage your authentication methods and account security
                  </p>
                  <div className='reveal-from-bottom' data-reveal-delay='600'>
                    <ButtonGroup className='justify-c'>
                      <Button tag='a' color='secondary' wideMobile href='/help'>
                        Help & Support Home
                      </Button>
                      <Button
                        tag='a'
                        color='primary'
                        wideMobile
                        href={process.env.REACT_APP_SLYZAPP_URL}
                      >
                        Launch Slyzed
                      </Button>
                    </ButtonGroup>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className='accordion-container'>
          <div className='accordion'>
            {authenticationManagementAccordionData.map(({ title, content }) => (
              <Accordion title={title} content={content} />
            ))}
          </div>
        </section>
      </>
    </>
  );
};

export default AuthenticationManagement;
