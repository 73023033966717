import React from 'react';
import classNames from 'classnames';
import { SectionProps } from '../../../utils/SectionProps';

const propTypes = {
  ...SectionProps.types,
};

const defaultProps = {
  ...SectionProps.defaults,
};

const HelpHero = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  ...props
}) => {
  const outerClasses = classNames(
    'hero section center-content',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'hero-inner ',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  return (
    <section {...props} className={outerClasses}>
      <div className='container-sm'>
        <div className={innerClasses}>
          <div className='hero-content'>
            <h1
              className='mt-0 mb-16 reveal-from-bottom'
              color='primary'
              data-reveal-delay='200'
            >
              Help & Support Center
            </h1>
            <div className='container-xs'>
              <p
                className='m-0 mb-32 ta-c reveal-from-bottom'
                data-reveal-delay='400'
              >
                Find help on all things related to the Slyzed platform
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

HelpHero.propTypes = propTypes;
HelpHero.defaultProps = defaultProps;

export default HelpHero;
