import React from 'react';
import classNames from 'classnames';
import Button from '../components/elements/Button';

const Privacy = (
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  ...props
) => {
  const outerClasses = classNames(
    'support-tile section',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'support-tile-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  return (
    <>
      <>
        <section {...props} className={outerClasses}>
          <div className='container-sm'>
            <div className={innerClasses}>
              <div className='hero-content'>
                <h1
                  className='mt-0 mb-16 reveal-from-bottom'
                  color='primary'
                  data-reveal-delay='200'
                >
                  Privacy Policy
                </h1>
                <div className='container-xs'>
                  <Button tag='a' color='secondary' wideMobile href='https://about.slyzed.co.ke/privacy-policy/'>
                  Read our privacy policy
                  </Button>
                 
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    </>
  );
};

export default Privacy;
