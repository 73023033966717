import React from "react";
import HelpHeader from "../components/layout/HelpHeader";
import Footer from "../components/layout/Footer";

const HelpLayout = ({ children }) => (
  <>
    <HelpHeader navPosition="right" className="reveal-from-bottom" />
    <main className="site-content">{children}</main>
    <Footer />
  </>
);

export default HelpLayout;
